<template>


    <b-row :key="componentKeyIndexNegocio">
   

      <b-col v-if="isHome" cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">

        <b-row>

          <b-col cols="12" sm="12" md="12" lg="3" xl="3"   order="3"
              order-sm="3"
              order-md="3"
              order-lg="1"
              order-xl="1">
            <plan :user-id="userId" :token-auth="tokenAuth"/>

        
          </b-col>


          <b-col cols="12" sm="12" md="12" lg="4" xl="4"  order="1"
              order-sm="1"
              order-md="1"
              order-lg="2"
              order-xl="2">

            <all-negocios :key="componentKeyAllNegocios" :user-id="userId" :token-auth="tokenAuth"/>

 

            <equipo :key="componentKeyEquipo" :user-id="userId" :token-auth="tokenAuth"/>

           

          </b-col>


          <b-col cols="12" sm="12" md="12" lg="5" xl="5" order="2"
              order-sm="2"
              order-md="2"
              order-lg="3"
              order-xl="3">

            <search :key="componentKeySearchNegocios" :user-id="userId" :token-auth="tokenAuth"/>

          </b-col>

        </b-row>



        </b-col>

        <b-col  v-else-if="isMyNegocio" cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">

     
       
          <negocio
         
                  :key="componentKeyNegocio"
                  :user-id="userId"
                  :is-local-not-complete="isLocalNotComplete"
                  :token-auth="tokenAuth"
                  :photo-local="photoLocal"
                  :banner-local="bannerLocal"
                  :description-local="descriptionLocal"
                  :is-negocio-verificated-local="isNegocioVerificatedLocal"
                  :name-local="nameLocal"
                  :alias-local="aliasLocal"
                  :type-document="typeDocument"
                  :id-local="idLocal"
                  :password="password"
                  :has-plan="hasPlan"
                />
      
                </b-col>

       

        <b-col v-else cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">


          <join
          :cargo="cargo"
          :porcentaje="porcentaje"
          :foto="foto"
          :display-name="displayName"
          :sueldo="sueldo"
          :has-plan="hasPlan"
                  :key="componentKeyJoin"
                  :user-id="userId"
                  :token-auth="tokenAuth"
                  :photo-local="photoLocal"
                  :banner-local="bannerLocal"
                  :description-local="descriptionLocal"
                  :is-negocio-verificated-local="isNegocioVerificatedLocal"
                  :name-local="nameLocal"
                  :alias-local="aliasLocal"
                  :id-local-employee="idLocalEmployee"
                  :id-local="idLocal"
                />



        </b-col>




     </b-row>


</template>

<script>
import { BRow, BCol,BCard,BLink, VBTooltip,VBPopover, BButton } from 'bootstrap-vue'
import AllNegocios from './AllNegocios.vue'
import Negocio from './Negocio.vue'
import Search from './Search.vue'
import Join from './Join.vue'
import Plan from './Plan.vue'
import Equipo from './Equipo.vue'
export default {
  components: {
    Plan,
    Join,
    BRow,
    BLink,
    AllNegocios,
    BCard,
    BCol,
    BButton,
    Search,
    Negocio,
    Equipo,
  },
  directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {

    const datos_json = JSON.parse(this.$userGlobal);

  
       

    return {
      isHome:true,
      isMyNegocio:false,
      userId: datos_json.userId,
      componentKeyIndexNegocio:100,
      tokenAuth: this.$tokenGlobal,
      componentKeyAllNegocios:200,
      componentKeyEquipo:300,
      componentKeySearchNegocios:400,
 

    
      isLocalNotComplete:false,
      idLocal:"",
      foto:"",
      porcentaje:"",
      cargo:"",
      displayName:"",
      idLocalEmployee:"",
      nameLocal:"",
      aliasLocal:"",
      typeDocument:"",
      password:"",


      photoLocal:"",
      bannerLocal:"",
      descriptionLocal:"",
      isNegocioVerificatedLocal:false,

      componentKeyNegocio:500,
      componentKeyJoin:600,
      addressLocal:"",
      hasPlan:false,
      sueldo:0,

      
    }
  },
  created() {

  },
  activated() {

    this.$eventBus.$emit('cuartoMenu1')
      this.$eventBus.$emit('cuartoMenu2')
},

  mounted() {

    this.$eventBus.$on('reiniciarNegocio', () => {
        this.componentKeyNegocio += 1
      })

      this.$eventBus.$on('reiniciarJoin', () => {
        this.componentKeyJoin += 1
      })

      
      this.$eventBus.$on('reiniciarAllNegocio', () => {
        this.componentKeyAllNegocios += 1
      })

      this.$eventBus.$on('reiniciarSearchNegocio', () => {
        this.componentKeySearchNegocios += 1
      })

      
      this.$eventBus.$on('reiniciarEquipo', () => {
        this.componentKeyEquipo += 1
      })
      

      this.$eventBus.$on('activeHome', () => {
        this.isHome=true;
        this.isMyNegocio=false;
      })

      this.$eventBus.$on('activeNegocio', (porcentaje, cargo,foto, displayName, sueldo,hasPlan,id,photo,name,banner,alias,description,isNegocioVerificated,idLocal) => {
        this.isHome=false;
        this.isMyNegocio=false;
        this.idLocalEmployee= id;
        this.nameLocal=name;
        this.photoLocal= photo;
        this.bannerLocal= banner;
        this.aliasLocal= alias;
        this.descriptionLocal= description;
        this.isNegocioVerificatedLocal= isNegocioVerificated;
        this.idLocal= idLocal;
        this.hasPlan=hasPlan;
        this.sueldo=sueldo;
        this.foto=foto;
        this.displayName=displayName;
        this.porcentaje=porcentaje;
        this.cargo=cargo;
      })

   
      this.$eventBus.$on('reiniciarIndexNegocio', () => {
   
                this.componentKeyIndexNegocio += 1
            })


      this.$eventBus.$on('openNegocio', (hasPlan,id,typeDocument,password, name, photo, banner, description, alias, address,isNegocioVerificated, isLocalNotComplete) => {
   
        
        this.idLocal = id;
                this.nameLocal = name;
                this.photoLocal = photo;
                this.bannerLocal = banner;
                this.descriptionLocal = description;
                this.isNegocioVerificatedLocal=isNegocioVerificated;
                this.aliasLocal = alias;
                this.typeDocument=typeDocument;
                this.password=password;
                this.addressLocal = address;
                this.hasPlan=hasPlan;
            

                    this.isLocalNotComplete=isLocalNotComplete;

                    this.isHome=false;
                    this.isMyNegocio=true;

      })



    document.title = 'EQCoop - Mis negocios'

  },
  methods: {


     sessionLink(){


        const userIdEncripted = this.$encryptBackoffice.encrypt(this.userId)

this.$https.post('/negocios/generateLink/', { tokenAuth: this.tokenAuth, userId: userIdEncripted }).then(response => {
    

   
  if (response.data.code == 200) {

  
      let sessionUrl= response.data.sessionUrl;

    window.location.href = sessionUrl;



   
  } else {

    this.$toast.error(response.data.message, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })


    if (response.data.code == 401) {
      
      localStorage.removeItem('userData')



      
      

       

      
      localStorage.removeItem('bannerUser')

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    }
  }
}).catch(error => {

    this.$toast.error(error, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
 
})



    },

    
    
   
  },
}
</script>

<style lang="scss" >

</style>
