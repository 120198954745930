<template>


<b-row style="margin-bottom:25px">

  <b-col  sm="12" md="12" lg="12" xl="12"  style="padding:0px">

      <div style="justify-content: space-between;display: flex;">
        <p style="text-align:left; font-size:16px;margin-top: 5px;    line-height: 16px;">        <feather-icon
            icon="ShoppingCartIcon"
            size="18"
            class="color_icon"
            style="margin-right: 10px;"
            /><strong>Mis empresas registradas</strong></p>

        
            <feather-icon
            @click="getNegocio()"
            icon="RefreshCcwIcon"
            size="18"
            style="cursor:pointer;margin-top: 5px;margin-right: 15px;"
            /> 
      </div>



        </b-col>


  
      <b-col v-if="loading" sm="12" md="12" lg="12" xl="12"  style="padding:0px">

        <b-skeleton width="100%"  height="40px" style="margin-bottom:10px"></b-skeleton>
        <b-card>
                        <b-row>

                          <b-col cols="3" sm="3" md="2" lg="2" xl="2" >

                            <b-skeleton type="avatar" width="40px"  height="40px"></b-skeleton>
                          </b-col>

                          <b-col cols="9" sm="9" md="10" lg="10" xl="10"  >
                           
                              <b-skeleton width="50%"  height="10px"></b-skeleton>
                            <b-skeleton width="100%"  height="10px" style="margin:0px"></b-skeleton>

                           
                         
                        </b-col>

                        </b-row>

                      </b-card>
      
                      <b-card>
                        <b-row>

                          <b-col cols="3" sm="3" md="2" lg="2" xl="2" >

                            <b-skeleton type="avatar" width="40px"  height="40px"></b-skeleton>
                          </b-col>

                          <b-col cols="9" sm="9" md="10" lg="10" xl="10"  >
                           
                              <b-skeleton width="50%"  height="10px"></b-skeleton>
                            <b-skeleton width="100%"  height="10px" style="margin:0px"></b-skeleton>

                           
                         
                        </b-col>

                        </b-row>

                      </b-card>



          
         
          </b-col>


          <b-col v-else-if="empty" sm="12" md="12" lg="12" xl="12" align="center" style="padding:0px">
        
        
             <b-card>
                          <b-row>


                            <b-col cols="3" sm="3" md="3" lg="3" xl="3" align="center" style="display:flex">

                              <div style="margin:auto">
                                <img src="/img/negocio.svg" style="width: 60px;"/>

                              </div>

                              </b-col>

                              <b-col cols="9" sm="9" md="9" lg="9" xl="9">


                              <p style="text-align:center; font-size:16px;margin-top: 5px; font-weight: bold;   line-height: 20px;">Aún no tienes ninguna empresa creada</p>
                              <b-button
                                    variant="outline-secondary"
                                    block
                                    @click="createBusiness()"
                                    style="margin-bottom: 0px;"
                                  >
                                   <b> Crear mi primera empresa</b>
                                  </b-button>

                            </b-col>

                            </b-row>
                     

                
                      </b-card>

                    

     


          
            </b-col>




            <b-col v-else-if="hasNegocios" sm="12" md="12" lg="12" xl="12" align="center" style="padding:0px">

              <div class="search-input">

                    <b-form-input
                    ref="searchInput"
                        v-model="searchQuery"
                        placeholder="Buscar por nombre, alias de la empresa ..."
                        autocomplete="off"
                    />
                    <div
                        class="search-input-close"
                    
                    >


                <div style="cursor:pointer;margin-right: 20px;   display: inline-block !important;"  @click="clear">
                <feather-icon icon="XIcon" size="18" style="    top: 5% !important; "  class="clear_icon" :class="{'show_clear': searchQuery}"/>
                    
                </div>


                </div>

                </div>

                <div :class="{'scroll_div_locales': minNegocios}">

                    <div v-if="startSearch">

                      <div v-if="items.length != 0">

                        <b-card class="card_opciones" @click="onChange2(item.id,item.typeDocument,item.password, item.name, item.photo, item.banner, item.description, item.alias, item.address, item.isNegocioVerificated, item.isLocalNotComplete)" v-for="item in items" :key="item.id">

                          <b-row>
                              <b-col cols="3" sm="3" md="2" lg="2" xl="2" align="center" style="display:flex"  >

                                <div style="margin:auto">

                                  <b-avatar
                                                    size="40"
                                                    :src="item.photo"
                                                    variant="light-primary"
                                                    style="margin:auto"
                                                  >
                                    
                                                  </b-avatar>

                                </div>






                                  </b-col>

                                  <b-col cols="9" sm="9" md="10" lg="10" xl="10" >

                                    <p class="p1_opciones" style="text-align:left; margin:0px">{{item.name}} </p>  
                                      <p class="p1_opciones" style="text-align:left;font-size:12px; font-weight: 400;">@{{item.alias}} </p>  
                                  
                                </b-col>
                          </b-row>

                          </b-card>


                        </div>

                        <div  v-else align="center">

                                                      
                        <svg width="100" height="100" viewBox="-20 0 190 190"  xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z" fill="#4a4a4a"/></svg>
                        <p style="text-align:center;font-size:16px">No se encontraron resultados.</p>


                        </div>

                    


                        </div>


                        <div v-else>

                          <b-card class="card_opciones" @click="onChange2(item.id,item.typeDocument,item.password, item.name, item.photo, item.banner, item.description, item.alias, item.address, item.isNegocioVerificated, item.isLocalNotComplete)" v-for="item in visiblePost" :key="item.id">

                          <b-row>
                              <b-col cols="3" sm="3" md="2" lg="2" xl="2" align="center" style="display:flex"  >

                                <div style="margin:auto">

                                  <b-avatar
                                                    size="40"
                                                    :src="item.photo"
                                                    variant="light-primary"
                                                    style="margin:auto"
                                                  >
                                    
                                                  </b-avatar>

                                </div>






                                  </b-col>

                                  <b-col cols="9" sm="9" md="10" lg="10" xl="10" >

                                    <p class="p1_opciones" style="text-align:left; margin:0px">{{item.name}} </p>  
                                      <p class="p1_opciones" style="text-align:left;font-size:12px; font-weight: 400;">@{{item.alias}} </p>  
                                  
                                     
                                  </b-col>
                          </b-row>

                          </b-card>




                              <div align="center">

                              
                            
                                <b-button
                                v-if="postVisible < locales.length"
                                                                
                                                                  variant="primary"
                                                                  style="margin-bottom:15px"
                                                                  size="md"
                                                                  @click="postVisible += step" 
                                                            
                                                                >
                                                              Cargar más negocios

                                                                </b-button>
                                  </div>

                            
                              </div>


                  </div>



              </b-col>

  


              

      </b-row>
   
      
      </template>
      
      <script>
      import {
        BButton, BRow, BCol, BCard,BSkeleton,BAvatar,VBTooltip,VBPopover,BAlert, BFormInput
      } from 'bootstrap-vue'

      
      export default {
        components: {
            BSkeleton,
            BAlert,
            BFormInput,
          BButton,
          BRow,
          BCol,
          BCard,
          BAvatar,
        },
        directives: {
      'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
        props: ['userId', 'tokenAuth'],
        data() {
      
      
          return {
            loading:true,   

            searchQuery:"",
            minNegocios:false,
            postVisible: 4,
            step: 4,
            startSearch:false,

            empty:false,
            hasNegocios:false,
            hasPlan:"Free",
            locales:[],
  
          }
        },
        computed: {

          visiblePost() {
          return this.locales.slice(0, this.postVisible)
          },

          items() {
                return this.locales.filter(item => {
                  return item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || item.alias.toLowerCase().includes(this.searchQuery.toLowerCase());
                });
              },
      
        },
        watch: {

          searchQuery(value){

            if(Number(value.length) != Number("0")){
              this.startSearch=true;
            }else{
              this.startSearch=false;
            }

            }
      
        },
        mounted() {




  
            this.getNegocio();
  
        },
        methods: {

          createBusiness(){

              this.$eventBus.$emit('createBusinessMethod')

          },

          clear(){
      
              this.$refs.searchInput.focus();
              this.searchQuery = '';

            },

            onChange2(id,typeDocument,password, name, photo, banner, description, alias, address,isNegocioVerificated, isLocalNotComplete ) {

                
              this.$eventBus.$emit('openNegocio', this.hasPlan,id,typeDocument,password, name, photo, banner, description, alias, address,isNegocioVerificated, isLocalNotComplete)

                },
  
       

                getNegocio(){

                  this.loading=true;
         
                this.$https.post('/locals/getNegocioByUser/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {

            
                if (response.data.code == 200) {
                
                    this.loading = false;


                    this.empty = false;
                    this.locales= response.data.negocios;
                    this.hasPlan= response.data.hasPlan;
                    this.hasNegocios=true;
             
                    if(Number(this.locales.length) > Number("2")){
                      this.minNegocios=true;
                    }


          


                } else {

            


                if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                    })
                    
                    localStorage.removeItem('userData')


                    this.$router.push({ name: 'auth-login' })
                } else {

                    if(response.data.code == 404){

                    this.hasNegocios=false;
                    this.loading= false;
                    this.empty=true;
          
                    }else{

             
                        this.getNegocio();
                    

                    
                    }
                    
                }
                }
                }).catch(error => {
                this.getNegocio();
                })
                },

         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      