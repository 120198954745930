<template>

    <b-row >
      <div id="overlay" ref="overlay" @click="off()"></div>
    

      <modal-profile-photo v-if="showModalPhoto" :key="componentKeyModalFoto" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />
      <modal-profile-banner v-if="showModalBanner" :key="componentKeyModalBanner" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />
      <modal-descripcion v-if="showModalDescripcion" :description="description" :key="componentKeyModalDescripcion" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />
      <modal-eliminar v-if="showModalEliminar" :key="componentKeyModalEliminar" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />
      <modal-pay-q v-if="showModalPay" :key="componentKeyModalPay" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />

     
  
      <b-col
        sm="12"
        md="12"
        xl="9"
        lg="9"
        order="2"
        order-sm="2"
        order-md="2"
        order-lg="1"
        order-xl="1"

      >
  
        <b-tabs
          vertical
          content-class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 mt-1 mt-md-0 sin_padding_profile"
          pills
          nav-wrapper-class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 card2 sin_padding_profile"
          nav-class="nav-left"
          v-model="activoPanel"
        >
  
          <b-tab>
  
            <template #title>
  
                      <feather-icon
                                            icon="ActivityIcon"
                                            size="15"
  
                                            class="margin-icon-profile"
                                            />
  
              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"> Estadísticas</span>
              <span class="titulo_menu d-block d-sm-block d-md-none d-lg-none d-xl-none">Estadísticas</span>
            </template>

            
  
            <estadisticas  v-if="ifEstadisticas" :user-id="userId" :token-auth="tokenAuth" :is-local-not-complete="isLocalNotComplete"  :id-local="idLocal" />
        


          </b-tab>

          <b-tab @click="GeneralLoad">

          <template #title>
                  <feather-icon
                                          icon="UserIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />

            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">  Documento de identidad</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Documento de identidad</span>
          </template>

          <general v-if="ifGeneral" :key="componentKeyGeneral" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />
          </b-tab>

          <b-tab @click="TeamLoad">

            <template #title>
                    <feather-icon
                                            icon="UsersIcon"
                                            size="15"

                                            class="margin-icon-profile"
                                            />

              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"> Equipo de trabajo</span>
              <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Equipo de trabajo</span>
            </template>

            <team v-if="ifTeam" :key="componentKeyTeam" :password="password" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" :tipo-documento="tipoDocumento" />
           
          </b-tab>


          <b-tab @click="WhatsappLoad">

            <template #title>
                    <feather-icon
                                            icon="BellIcon"
                                            size="15"

                                            class="margin-icon-profile"
                                            />

              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">  Notificaciones</span>
              <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Notificaciones</span>
            </template>

            <whatsapp v-if="ifWhatsapp" :key="componentKeyWhatsapp" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />
            </b-tab>

            
          <b-tab @click="UbicacionLoad">

            <template #title>
                    <feather-icon
                                            icon="MapPinIcon"
                                            size="15"

                                            class="margin-icon-profile"
                                            />

              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">  Ubicación de la empresa</span>
              <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none"> Ubicación de la empresa</span>
            </template>

            <ubicacion v-if="ifUbicacion" :key="componentKeyUbicacion" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />
            </b-tab>

            <b-tab @click="ProductosLoad">

                <template #title>
                        <feather-icon
                                                icon="StarIcon"
                                                size="15"

                                                class="margin-icon-profile"
                                                />

               
                  <span class="titulo_menu"> Catálogo de publicaciones</span>
                </template>

                <productos v-if="ifProductos" :key="componentKeyProductos" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" :has-plan="hasPlan" />
                </b-tab>


                <b-tab @click="SolicitudesLoad">

                  <template #title>
                          <feather-icon
                                                  icon="DollarSignIcon"
                                                  size="15"

                                                  class="margin-icon-profile"
                                                  />


                    <span class="titulo_menu"> Historial adelantos de sueldos</span>
                  </template>

                  <solicitudes-adelantos v-if="ifSolicitudes" :key="componentKeySolicitudes" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />
                  </b-tab>

                  <b-tab @click="VentasLoad">

                    <template #title>
                            <feather-icon
                                                    icon="ShoppingBagIcon"
                                                    size="15"

                                                    class="margin-icon-profile"
                                                    />


                      <span class="titulo_menu"> Mis ventas pendientes <count-ventas :key="componentKeyCountVentas"  :user-id="userId" :token-auth="tokenAuth"  :id-local="idLocal" /> </span>
                    </template>

                    <b-card>
                      <listado-ventas v-if="ifVentas" :key="componentKeyVentas" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />
                 
                    </b-card>

                   </b-tab>
      
  
          
  
        </b-tabs>
  
      </b-col>

      <b-col
        sm="12"
        md="12"
        order="1"
        order-sm="1"
        order-md="1"
        order-lg="2"
        order-xl="2"
        xl="3"
        lg="3"
    
      >
  
  
      <b-link @click="modalBanner" class="camera_icon2"> 
  
          <b-avatar
          size="25"
          variant="light-primary"
          style="cursor:pointer;">
  
          <span class="d-flex align-items-center">
  
  
            <feather-icon
               icon="CameraIcon"
                size="18"
                 
               /> 
  
          </span>
  
          </b-avatar>
  
          </b-link>
  
  
  
  
  
        <b-card
          :img-src="banner"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
  
          <b-row>
  
            
            <b-col cols="3" sm="2" md="2" xl="4" lg="4" align="center">
  
              <b-link @click="modalPhoto" class="camera_icon"> 
  
                  <b-avatar
                  size="21"
                  variant="light-primary"
                  style="cursor:pointer;">
  
                  <span class="d-flex align-items-center">
  
  
                    <feather-icon
                      icon="CameraIcon"
                        size="16"
                        
                      /> 
  
                  </span>
  
                  </b-avatar>
  
                  </b-link>
  
  
  
                  <div class="profile-image p-0" style="    text-align: center;">
  
  
                  <b-avatar
                  size="40"
                  variant="light"
                  :src="photo"
                  class="border_profile3"
                  />
                  </div>
  
              </b-col>
  
  
              <b-col cols="9" sm="10" md="10" xl="8" lg="8" >
  
                <h4 class="font-weight-bolder text_align_p" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;margin-top: 12px;margin-bottom: 0px;font-size: 14px;text-align: left !important;">
                      {{ name }}
                    </h4>
  
                    <h6
                      v-if="alias"
                      class="text-muted text_align_p"
                      style="text-align: left !important;"
                     
                    >
                      @{{ alias }}   <img v-if="isNegocioVerificated" title="Negocio verificado" alt="Negocio verificado" class="badgeVerificacion2" src="/img/verificadoFinal.svg" />
                    <img v-else alt="Negocio no verificado" title="Negocio no verificado" class="badgeVerificacion2" src="/img/no_verificadoFinal.svg" />

                    </h6>
  
  
  
                </b-col>
  
  
  
  
              <b-col cols="12" sm="12" md="12" xl="12" lg="12" class="biografiatext">

                <read-more more-str="Más información" :text="description" link="#" less-str="Menos información" :max-chars="100"></read-more>


              
              </b-col>


              <b-col cols="8" sm="8" md="8" xl="8" lg="8">

                <b-button 
                                                            @click="editarDescription"
                                                            variant="outline-secondary"
                                                            size="sm"
                                                            block
                                                            style="margin-top:15px; margin-bottom:10px; display:flex"
                                                    
                                                          >
                                                          <feather-icon

                                                              icon="EditIcon"
                                                              size="17"
                                                              style= "margin-right:2px"
                                                              />

                                                              <span style="margin:auto"> Editar biografía  </span>
                                                           

                                                          </b-button>

              </b-col>

              <b-col cols="4" sm="4" md="4" xl="4" lg="4">

                <b-button
                size="sm"
                block
                   style="margin-top:15px; margin-bottom:10px; display:flex"
                          variant="danger"
                        @click="deleteNegocio"
                        >

                        <feather-icon
                              icon="TrashIcon"
                              size="14"
                              style="margin-right: 5px;"
                              />Eliminar

                        </b-button>
               


              </b-col>


  
  
     
        </b-row>
      
  
  
  
        </b-card>
  
      </b-col>
      <a  v-if="isMobile" class="float" @click="createPay()" >

 
        <svg  width="30" class="my-float" style="margin-top: 15px;" height="30" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M4 4h4V2H2v6h2zM4 16H2v6h6v-2H4zm16 4h-4v2h6v-6h-2zM16 4h4v4h2V2h-6z"/><path d="M5 11h6V5H5v6zm2-4h2v2H7V7zM5 19h6v-6H5v6zm2-4h2v2H7v-2zM19 5h-6v6h6V5zm-2 4h-2V7h2v2zm-4 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z"/></svg>
        
      

      </a> 


      <a  v-else class="float" @click="createPay()"  v-b-tooltip.hover.right="'Crear QR para pago rápido'">

 
        <svg  width="30" class="my-float" style="margin-top: 15px;" height="30" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M4 4h4V2H2v6h2zM4 16H2v6h6v-2H4zm16 4h-4v2h6v-6h-2zM16 4h4v4h2V2h-6z"/><path d="M5 11h6V5H5v6zm2-4h2v2H7V7zM5 19h6v-6H5v6zm2-4h2v2H7v-2zM19 5h-6v6h6V5zm-2 4h-2V7h2v2zm-4 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z"/></svg>



        </a> 

        <a  v-if="isMobile" class="float3" @click="onChangeOption('change')" >

          <svg  width="18" class="my-float" style="margin-top: 12px;" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52"><path d="M20 37.5c0-.8-.7-1.5-1.5-1.5h-15C2.7 36 2 36.7 2 37.5v11C2 49.3 2.7 50 3.5 50h15c.8.0 1.5-.7 1.5-1.5v-11z"/><path d="M8.1 22H3.2c-1 0-1.5.9-.9 1.4l8 8.3c.4.3 1 .3 1.4.0l8-8.3c.6-.6.1-1.4-.9-1.4h-4.7c0-5 4.9-10 9.9-10V6C15 6 8.1 13 8.1 22z"/><path d="M41.8 20.3c-.4-.3-1-.3-1.4.0l-8 8.3c-.6.6-.1 1.4.9 1.4h4.8c0 6-4.1 10-10.1 10v6c9 0 16.1-7 16.1-16H49c1 0 1.5-.9.9-1.4l-8.1-8.3z"/><path d="M50 3.5C50 2.7 49.3 2 48.5 2h-15C32.7 2 32 2.7 32 3.5v11c0 .8.7 1.5 1.5 1.5h15c.8.0 1.5-.7 1.5-1.5V3.5z"/></svg>



</a> 
         
        <a v-else  class="float3" @click="onChangeOption('change')"  v-b-tooltip.hover.right="'Cambiar de empresa'">

          <svg  width="18" class="my-float" style="margin-top: 12px;" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52"><path d="M20 37.5c0-.8-.7-1.5-1.5-1.5h-15C2.7 36 2 36.7 2 37.5v11C2 49.3 2.7 50 3.5 50h15c.8.0 1.5-.7 1.5-1.5v-11z"/><path d="M8.1 22H3.2c-1 0-1.5.9-.9 1.4l8 8.3c.4.3 1 .3 1.4.0l8-8.3c.6-.6.1-1.4-.9-1.4h-4.7c0-5 4.9-10 9.9-10V6C15 6 8.1 13 8.1 22z"/><path d="M41.8 20.3c-.4-.3-1-.3-1.4.0l-8 8.3c-.6.6-.1 1.4.9 1.4h4.8c0 6-4.1 10-10.1 10v6c9 0 16.1-7 16.1-16H49c1 0 1.5-.9.9-1.4l-8.1-8.3z"/><path d="M50 3.5C50 2.7 49.3 2 48.5 2h-15C32.7 2 32 2.7 32 3.5v11c0 .8.7 1.5 1.5 1.5h15c.8.0 1.5-.7 1.5-1.5V3.5z"/></svg>



          </a> 


      

  
    </b-row>
  
  </template>
  
  <script>
  import {
    BRow, BCard, BForm,  BCol, BButton, BAvatar, BTabs, BTab, BFormFile,BLink,BFormTextarea, BDropdown, BDropdownItem,  VBTooltip,VBPopover,
  } from 'bootstrap-vue'


  /* eslint-disable global-require */
  export default {
    components: {
      ModalProfilePhoto: () => import('./ModalProfilePhoto.vue'),
      ModalProfileBanner: () => import('./ModalProfileBanner.vue'),
      ModalDescripcion: () => import('./ModalDescripcion.vue'),
      ModalEliminar: () => import('./ModalEliminar.vue'),
      ModalPayQ: () => import('./ModalPayQ.vue'),
      Estadisticas: () => import('./Estadisticas.vue'),
      Whatsapp: () => import('./Whatsapp.vue'),
      General: () => import('./General.vue'),
      Team: () => import('./Team.vue'),
      Ubicacion: () => import('./Ubicacion.vue'),
      Productos: () => import('./Productos.vue'),
      SolicitudesAdelantos: () => import('./SolicitudesAdelantos.vue'),
      ListadoVentas: () => import('./ListadoVentas.vue'),
      CountVentas: () => import('./CountVentas.vue'),
      BDropdown, BDropdownItem,
      BRow,
      BFormTextarea,
      BLink,
      BCol,
      BForm,
      BCard,
      BAvatar,
      BTabs,
      BTab,
      BFormFile,
      BButton,
      
    },
    directives: {
      'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth','isLocalNotComplete','idLocal','photoLocal','hasPlan','bannerLocal','descriptionLocal','isNegocioVerificatedLocal', 'nameLocal', 'aliasLocal' ,'typeDocument','password'],
    data() {
  
   
  
      return {
        show_menu:false,
        isMobile:this.$isMobile,
        activoPanel:0,
        photo:this.photoLocal,
        tipoDocumento: this.typeDocument,
        banner:this.bannerLocal,
        description: this.descriptionLocal,
        name:this.nameLocal,
        ifWhatsapp:false,
        ifUbicacion:false,
        ifProductos:false,
        ifSolicitudes:false,
        ifVentas:false,
        ifGeneral:false,
        ifTeam:false,
        ifEstadisticas:true,
        componentKeyWhatsapp:100,
        componentKeyUbicacion:200,
        componentKeyProductos:300,
        componentKeyTeam:400,
        componentKeyGeneral:500,
        alias:this.aliasLocal,
        isNegocioVerificated:this.isNegocioVerificatedLocal,
        showModalPay:false,
        showModalEliminar:false,
        showModalPhoto:false,
        showModalBanner:false,
        showModalDescripcion:false,
        componentKeyModalBanner:600,
        componentKeyModalEliminar:700,
        componentKeyModalPay:800,
        componentKeyModalFoto:900,
        componentKeyModalDescripcion:1000,
        componentKeySolicitudes:1100,
        componentKeyVentas:1200,
        componentKeyCountVentas:1300,
        body: document.getElementsByTagName('body')[0],
        typePay:"",
      }
    },
    created() {
  
    },watch:{

     


  },
    mounted() {


      this.$eventBus.$on('reiniciarGeneralNegocio', () => {
      this.componentKeyGeneral += 1
    })

    this.$eventBus.$on('reiniciarTeamNegocio', (tipoDocumento) => {
      this.componentKeyTeam += 1
      this.tipoDocumento=tipoDocumento;
    })


      this.$eventBus.$on('reiniciarWhatsappNegocio', () => {
      this.componentKeyWhatsapp += 1
    })

    this.$eventBus.$on('reiniciarUbicacion', () => {
      this.componentKeyUbicacion += 1
    })

    this.$eventBus.$on('reiniciarProductos', () => {
      this.componentKeyProductos += 1
    })

    this.$eventBus.$on('reiniciarCountVentas2', () => {
      this.componentKeyCountVentas += 1
    })


    this.$eventBus.$on('reiniciarSolicitudesAdelantos', () => {
      this.componentKeySolicitudes += 1
    })

    this.$eventBus.$on('reiniciarVentasMarketplace', () => {
      this.componentKeyVentas += 1
    })
    
    

    
    

    this.$eventBus.$on('updatePhotoNegocio', (url) => {
      this.photo = url;
    
    })

    this.$eventBus.$on('updateBannerNegocio', (url) => {
      this.banner = url;
    
    })

    this.$eventBus.$on('updateDescription', (description) => {
      this.description = description;
    
    })

    this.$eventBus.$on('reiniciarModalDescripcion', () => {
      this.componentKeyModalDescripcion += 1
      this.showModalDescripcion=false;
    })


    

    this.$eventBus.$on('clickSolicitudes', () => {
      this.SolicitudesLoad();
    })

    this.$eventBus.$on('clickProductos', () => {
      this.ProductosLoad();
    })

    this.$eventBus.$on('clickUbicacion', () => {
      this.UbicacionLoad();
    })

    this.$eventBus.$on('clickNotificaciones', () => {
      this.WhatsappLoad();
    })

    this.$eventBus.$on('clickGeneral', () => {
      this.GeneralLoad();
    })


    this.$eventBus.$on('clickTeam', () => {
      this.TeamLoad();
    })

    

    this.$eventBus.$on('reiniciarModalPay', () => {
      this.componentKeyModalPay += 1
      this.showModalPay=false;
    })

    this.$eventBus.$on('reiniciarModalEliminar', () => {
      this.componentKeyModalEliminar += 1
      this.showModalEliminar=false;
    })

    this.$eventBus.$on('reiniciarModalBanner', () => {
      this.componentKeyModalBanner += 1
      this.showModalBanner=false;
    })

    this.$eventBus.$on('reiniciarModalPhoto', () => {
      this.componentKeyModalFoto += 1
      this.showModalPhoto=false;
    })


    this.$eventBus.$on('showModalPhoto', () => {

      this.showModalPhoto=true;
    })

        


    
    this.$eventBus.$on('showModalBanner', () => {

      this.showModalBanner=true;
      })
  
   
    },
    methods: {
      clickOpen(){

      this.show_menu=true;
      this.$refs.overlay.style.display="block";

      },

      off(){
      this.show_menu=false;
      this.$refs.overlay.style.display="none";
      },
      TeamLoad(){
        this.ifTeam = true
        this.activoPanel=2;
      },
      GeneralLoad(){
        this.ifGeneral = true
        this.activoPanel=1;
      },

      WhatsappLoad(){
     
        this.ifWhatsapp = true
        this.activoPanel=3;

      },
      ProductosLoad(){
     
     this.ifProductos = true
     this.activoPanel=5;

   },

   VentasLoad(){
    this.ifVentas = true
   },

   SolicitudesLoad(){
    this.ifSolicitudes = true
     this.activoPanel=6;
   },
      
      UbicacionLoad(){
     
     this.ifUbicacion = true
     this.activoPanel=4;

   },

      deleteNegocio(){
       this.showModalEliminar=true;

      },

      createPay(){

        if(!this.hasPlan){

          this.$toast.error("Funcionalidad no disponible para negocios con plan Free. Por favor actualize su plan.", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

          
        }else{

          this.showModalPay=true;

        }


       

      
     

      },

      onChangeOption(option){
     
 
          if(option === 'change'){
            this.body.classList.remove("overflow-hidden");

            this.$eventBus.$emit('activeHome');
           // this.$eventBus.$emit('reiniciarAllNegocio');
         
          }else{
            if(option === 'update'){
          

              this.$toast.error("Funcionalidad no disponible por el momento", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

            

            }
          }
        

      },

      editarDescription(){

        this.showModalDescripcion=true;


      },
  
      
  
      modalPhoto() {
       this.showModalPhoto=true;
      },
  
      modalBanner() {

        this.showModalBanner=true;

      
      },

     
      
    
  
    },
  }
  /* eslint-disable global-require */
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/pages/page-profile.scss';
 









  </style>
  