<template>

    <b-row >
    
      <modal-pay-q v-if="showModalPay" :key="componentKeyModalPay" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />

      <b-col
       cols="12"
        sm="12"
        md="12"
        xl="3"
        lg="3"
      >

  
        <b-card
          :img-src="banner"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
  
          <b-row>
  
            
            <b-col cols="3" sm="2" md="2" xl="4" lg="4" align="center">
  
          
  
  
  
                  <div class="profile-image p-0" style="    text-align: center;">
  
  
                  <b-avatar
                  size="40"
                  variant="light"
                  :src="photo"
                  class="border_profile3"
                  />
                  </div>
  
              </b-col>
  
  
              <b-col cols="9" sm="10" md="10" xl="8" lg="8" >
  
                <h4 class="font-weight-bolder text_align_p" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;margin-top: 12px;margin-bottom: 0px;font-size: 14px;text-align: left !important;">
                      {{ name }}
                    </h4>
  
                    <h6
                      v-if="alias"
                      class="text-muted text_align_p"
                      style="text-align: left !important;"
                     
                    >
                      @{{ alias }}   <img v-if="isNegocioVerificated" title="Negocio verificado" alt="Negocio verificado" class="badgeVerificacion2" src="/img/verificadoFinal.svg" />
                    <img v-else alt="Negocio no verificado" title="Negocio no verificado" class="badgeVerificacion2" src="/img/no_verificadoFinal.svg" />

                    </h6>
  
  
  
                </b-col>
  
  
  
  
              <b-col cols="12" sm="12" md="12" xl="12" lg="12" class="biografiatext">

                <read-more more-str="Más información" :text="description" link="#" less-str="Menos información" :max-chars="100"></read-more>


              
              </b-col>


  
     
        </b-row>
      
  
  
  
        </b-card>
  
      </b-col>

      <b-col
       cols="12"
        sm="12"
        md="12"
        xl="6"
        lg="6"
      >

      
  
      <b-tabs
      class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1 mt-md-0 sin_padding"
      pills
      fill  
      nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 card3"
      style="    padding: 0;"

        >
  
          <b-tab>
  
            <template #title>
  
                      <feather-icon
                                            icon="DollarSignIcon"
                                            size="15"
  
                                            class="margin-icon-profile"
                                            />
  
              <span class="titulo_menu"> Adelantos de sueldo</span>
            </template>

            
  
        <adelantos v-if="ifAdelantos" :id-local-employee="idLocalEmployee" :key="componentKeyAdelantos" :sueldo="sueldo" :user-id="userId" :token-auth="tokenAuth"  />

          </b-tab>

          <b-tab @click="ListadoLoad()">
            
            <template #title>

                      <feather-icon
                                            icon="ListIcon"
                                            size="15"

                                            class="margin-icon-profile"
                                            />

              <span class="titulo_menu"> Listado de adelantos</span>
            </template>

            

          <listado v-if="ifListado" :id-local-employee="idLocalEmployee" :key="componentKeyListado" :user-id="userId" :token-auth="tokenAuth"  />

          </b-tab>

         
  
          
  
        </b-tabs>



      </b-col>

      <b-col
       cols="12"
        sm="12"
        md="12"
        xl="3"
        lg="3"
      >

      <b-card>

        <div align="center">
          <b-avatar
                    size="80"
                    variant="light-primary"
                    >

                    <b-img-lazy  v-bind="mainProps"  style="border-radius: 50px;"  :src="foto" :alt="displayName"></b-img-lazy>

                    </b-avatar>

        </div>


                    <p style="text-align:center; font-style: italic; font-size: 12px; ">{{ displayName }}</p>

                    <p style="text-align:left;  font-size: 12px; "><b>Cargo:</b> {{ cargo }}</p>

                    <p style="text-align:left;  font-size: 12px; "><b>Sueldo:</b> ${{ sueldo }} USD</p>
                    <p style="text-align:left;  font-size: 12px; "><b>Porcentaje máximo de adelanto de sueldo:</b> {{ porcentaje }}%</p>

      </b-card>



      </b-col>



      
      <a  v-if="isMobile" class="float" @click="createPay()" >

 
        <svg  width="30" class="my-float" style="margin-top: 15px;" height="30" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M4 4h4V2H2v6h2zM4 16H2v6h6v-2H4zm16 4h-4v2h6v-6h-2zM16 4h4v4h2V2h-6z"/><path d="M5 11h6V5H5v6zm2-4h2v2H7V7zM5 19h6v-6H5v6zm2-4h2v2H7v-2zM19 5h-6v6h6V5zm-2 4h-2V7h2v2zm-4 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z"/></svg>
        
      

      </a> 


      <a  v-else class="float" @click="createPay()"  v-b-tooltip.hover.right="'Crear QR para pago rápido'">

 
        <svg  width="30" class="my-float" style="margin-top: 15px;" height="30" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M4 4h4V2H2v6h2zM4 16H2v6h6v-2H4zm16 4h-4v2h6v-6h-2zM16 4h4v4h2V2h-6z"/><path d="M5 11h6V5H5v6zm2-4h2v2H7V7zM5 19h6v-6H5v6zm2-4h2v2H7v-2zM19 5h-6v6h6V5zm-2 4h-2V7h2v2zm-4 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z"/></svg>



        </a> 

        <a  v-if="isMobile" class="float3" @click="onChangeOption('change')" >

          <svg  width="18" class="my-float" style="margin-top: 12px;" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52"><path d="M20 37.5c0-.8-.7-1.5-1.5-1.5h-15C2.7 36 2 36.7 2 37.5v11C2 49.3 2.7 50 3.5 50h15c.8.0 1.5-.7 1.5-1.5v-11z"/><path d="M8.1 22H3.2c-1 0-1.5.9-.9 1.4l8 8.3c.4.3 1 .3 1.4.0l8-8.3c.6-.6.1-1.4-.9-1.4h-4.7c0-5 4.9-10 9.9-10V6C15 6 8.1 13 8.1 22z"/><path d="M41.8 20.3c-.4-.3-1-.3-1.4.0l-8 8.3c-.6.6-.1 1.4.9 1.4h4.8c0 6-4.1 10-10.1 10v6c9 0 16.1-7 16.1-16H49c1 0 1.5-.9.9-1.4l-8.1-8.3z"/><path d="M50 3.5C50 2.7 49.3 2 48.5 2h-15C32.7 2 32 2.7 32 3.5v11c0 .8.7 1.5 1.5 1.5h15c.8.0 1.5-.7 1.5-1.5V3.5z"/></svg>



</a> 
         
        <a v-else  class="float3" @click="onChangeOption('change')"  v-b-tooltip.hover.right="'Cambiar de empresa'">

          <svg  width="18" class="my-float" style="margin-top: 12px;" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52"><path d="M20 37.5c0-.8-.7-1.5-1.5-1.5h-15C2.7 36 2 36.7 2 37.5v11C2 49.3 2.7 50 3.5 50h15c.8.0 1.5-.7 1.5-1.5v-11z"/><path d="M8.1 22H3.2c-1 0-1.5.9-.9 1.4l8 8.3c.4.3 1 .3 1.4.0l8-8.3c.6-.6.1-1.4-.9-1.4h-4.7c0-5 4.9-10 9.9-10V6C15 6 8.1 13 8.1 22z"/><path d="M41.8 20.3c-.4-.3-1-.3-1.4.0l-8 8.3c-.6.6-.1 1.4.9 1.4h4.8c0 6-4.1 10-10.1 10v6c9 0 16.1-7 16.1-16H49c1 0 1.5-.9.9-1.4l-8.1-8.3z"/><path d="M50 3.5C50 2.7 49.3 2 48.5 2h-15C32.7 2 32 2.7 32 3.5v11c0 .8.7 1.5 1.5 1.5h15c.8.0 1.5-.7 1.5-1.5V3.5z"/></svg>



          </a> 


      

  
    </b-row>
  
  </template>
  
  <script>
  import {
    BRow, BCard,  BCol, BButton, BAvatar, BTabs, BTab, BImgLazy, VBPopover, VBTooltip
  } from 'bootstrap-vue'


  /* eslint-disable global-require */
  export default {
    components: {
      ModalPayQ: () => import('./ModalPayQ.vue'),
      Adelantos: () => import('./Adelantos.vue'),
      Listado: () => import('./Listado.vue'),
      BImgLazy,
      BRow,
      BCol,
      BCard,
      BAvatar,
      BTabs,
      BTab,
      BButton,
      
    },
    directives: {
      'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','cargo','porcentaje','hasPlan','foto','displayName','tokenAuth','sueldo','idLocalEmployee','idLocal','photoLocal','bannerLocal','descriptionLocal','isNegocioVerificatedLocal', 'nameLocal', 'aliasLocal'],
    data() {
  
   

  
      return {
        show_menu:false,
        mainProps: {
                      center: true,
                      fluidGrow: true,
                      blank: true,
                      blankColor: '#bbb',


                    },
        isMobile:this.$isMobile,
        photo:this.photoLocal,
        banner:this.bannerLocal,
        description: this.descriptionLocal,
        name:this.nameLocal,
        componentKeyModalPay:100,
        showModalPay:false,
        alias:this.aliasLocal,
        isNegocioVerificated:this.isNegocioVerificatedLocal,
        ifAdelantos:true,
        ifListado:false,
        componentKeyAdelantos:200,
        componentKeyListado:300,
        body: document.getElementsByTagName('body')[0],
      }
    },
    created() {
  
    },watch:{

     


  },
    mounted() {

      

      this.$eventBus.$on('reiniciarAdelantos', () => {
      this.componentKeyAdelantos += 1

    })

    this.$eventBus.$on('reiniciarListadoAdelantos', () => {
      this.componentKeyListado += 1

    })


      this.$eventBus.$on('reiniciarModalPay', () => {
      this.componentKeyModalPay += 1
      this.showModalPay=false;
    })
   
    },
    methods: {
      clickOpen(){

      this.show_menu=true;
      this.$refs.overlay.style.display="block";

      },

      ListadoLoad(){

        this.ifListado=true;

      },
     

      createPay(){

        if(!this.hasPlan){

          this.$toast.error("Funcionalidad no disponible para negocios con plan Free. Por favor actualize su plan.", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

          
        }else{

          this.showModalPay=true;

        }


       

      
     

      },

      onChangeOption(option){
     
 
          if(option === 'change'){
            this.body.classList.remove("overflow-hidden");

            this.$eventBus.$emit('activeHome');
           // this.$eventBus.$emit('reiniciarAllNegocio');
         
          }
        

      },

  
   
    
  
    },
  }
  /* eslint-disable global-require */
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/pages/page-profile.scss';
 









  </style>
  