<template>

       
<b-modal
        ref="modalUnirse"
        centered
        hide-footer
        size="sm"
        @hidden="closeModalUnirse"
        hideHeaderClose
        hideHeader
      >
        <div class="d-block text-center">
  
          <b-form
            autocomplete="off"
            @submit.prevent="onSubmit"
          >
  
            <b-row>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="position: absolute;top: -43px;width: 385px;" >

                    <b-avatar
                                        size="80"
                                        :src="photo"
                                        variant="light-primary"
                                       
                                    >
                        
                                    </b-avatar>


                                    <p class="textLabel" style="text-align: center;font-size:15px;margin-top: 0px;margin-bottom: 0px;">
                       {{ name }}</p>

                       <p  style="text-align: center;font-size:12px;font-style:italic;margin-top: 0px;margin-bottom: 15px;">
                       @{{ alias }}</p>

                    </b-col>

  
              <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      style="margin-bottom: 10px;margin-top: 85px;"
                    >


          

                        <b-form-input
                                 v-model="passwordInput"
                                 autofocus
                                 placeholder="Ingrese la contraseña de ingreso"
                                 required
                                 type="text"
                                 :readonly="isActive"
                                
                               />
                    

                    </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <p
                  class="textLabel"
                  style="text-align: center;margin-bottom: 10px;"
                >
                  Ingrese su código PIN (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                      v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                    title="Código PIN de seguridad"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /> </p>
  
                <PincodeInput
                  v-model="pin"
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                  :disabled="isActive"
                  required
                />
  
              </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <b-button
                  id="btn_password"
                  ref="btn_password"
                  type="submit"
                  variant="primary"
                  :disabled="isActive"
                  block
                >
  
                  Unirse al negocio
  
                </b-button>
  
              </b-col>
  
            </b-row>
          </b-form>
  
        </div>
  
      </b-modal>
          
          </template>
          
          <script>
          import {
            BButton, BRow,VBPopover, BCol, BCard,BFormInput, BForm, BAvatar
          } from 'bootstrap-vue'
          
          import PincodeInput from 'vue-pincode-input'
          
          export default {
            components: {
                PincodeInput,
                BFormInput,
                BForm,
              BButton,
              BRow,
              BCol,
              BCard,
              BAvatar,
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: ['idLocal', 'tokenAuth', 'userId', 'photo' , 'name', 'alias'],
            data() {
          
          
              return {
         
                pin:"",
      
                passwordInput:"",
            isActive:false,
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
       
                this.openModalPassword();
           
            },
            methods: {
    
                  
    closeModalUnirse(){
                    this.$eventBus.$emit('reiniciarModalUnirse')
                },
      
                openModalPassword(){
          
          this.pin="";
          this.passwordInput="";
         this.$refs.modalUnirse.show()

        },

   onSubmit(event) {
    event.preventDefault();

    

this.isActive=true;
    
    const local_json = {
       userId: this.userId, password: this.passwordInput, pin: this.pin, id: this.idLocal
      }
    
    
    
    
    
    const local_string = JSON.stringify(local_json)
    
    const dataEncripted = this.$encryptBackoffice.encrypt(local_string)
    
    document.getElementById('btn_password').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Uniéndose'
    
    
    this.$https.post('/locals/joinNegocio/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
    document.getElementById('btn_password').innerHTML = 'Unirse al negocio';
    this.isActive=false;
    
    this.$refs.modalUnirse.hide();
    
    if (response.data.code == 200) {
    

  
    this.$toast.success(response.data.message, {
      position: 'top-right',
      timeout: 3010,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.4,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      rtl: false,
    });
    this.$eventBus.$emit('reiniciarEquipo');
    
    
    } else {
    
    this.$toast.error(response.data.message, {
      position: 'top-right',
      timeout: 3010,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.4,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      rtl: false,
    })
    
    if (response.data.code == 401) {
    
    
    
    localStorage.removeItem('userData')
    
    
    this.$router.push({ name: 'auth-login' })
    } 
    }
    }).catch(error => {

        this.$refs.modalUnirse.hide();
    
    this.$toast.error("Ocurrio un error " + error, {
      position: 'top-right',
      timeout: 3010,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.4,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      rtl: false,
    })
    })

  },
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          