<template>
<div>
  <modal-create-negocio v-if="openModalNegocio" :key="componentKeyModalNegocio"  :user-id="userId" :token-auth="tokenAuth"  />


  <b-card style="margin-bottom: 10px;">

    <b-row v-if="loading">

      <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12">

        <b-skeleton width="50%" height="10px" style="margin-bottom:5px !important" ></b-skeleton>
           
        <b-skeleton width="100%" height="40px" style="margin-bottom:5px !important" ></b-skeleton>
                      

        <b-skeleton width="60%" height="10px" style="margin-bottom:5px !important" ></b-skeleton>
        <b-skeleton width="80%" height="20px" style="margin-bottom:5px !important" ></b-skeleton>
                     
           

      </b-col>


    </b-row>

    <b-row v-else-if="hasPlan">
      <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12">

        <img src="/img/star.svg" style="width: 80px;" />
     
        <p style="margin-bottom:15px;margin-bottom:10px;text-align: center;font-size:16px;font-weight: bold;"  >Tienes una suscripción activa</p>

   
      </b-col>

      <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12">

        <p style="margin-bottom:0px; font-size:12px;text-align: center;">Fecha del próximo pago</p>
                 
                 <p style="margin-bottom:0px;margin-bottom:5px;text-align: center;font-size:14px;font-weight: bold;"  >{{ DateProxPay }}</p>



      </b-col>

      <a v-if="showCreateNegocio"  class="float" @click="createBusiness()" v-b-tooltip.hover.right="'Crear nuevo negocio'" >

          <feather-icon
          style="margin-top: 15px;color:white"
                                          
                                                  icon="ShoppingBagIcon"
                                                  size="30"
                                                />





          </a> 

    </b-row>

    <b-row v-else>
      

      <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12" >
      
          <img style="width:70px;" src="/img/caution.svg" />
                 
                 <p style="margin-top:10px;margin-bottom:10px;text-align: center;font-size:18px;font-weight: bold;"  >No tienes activado ningún plan</p>
                 <p style="margin-bottom:0px;margin-bottom:5px;text-align: center;font-size:14px;"  >Suscríbete al plan negocios de EQCoop y desbloquea todas las funcionalidades.</p>

      
                 <b-button 
                 :to="{ name: 'marketplaceparam', params: { id: id }}"
                      variant="primary"
                      size="md"
                      block
                      style="margin-top:10px; margin-bottom:0px"
                      class="animacion_button" 
                    >
                    <feather-icon

                      icon="AwardIcon"
                      size="18"
                      style= "margin-right:2px"
                      />
              
                      Suscribirme al plan negocios EQCoop

                    </b-button>
                    
   
      </b-col>

      </b-row>
                
                    

                </b-card>
    
              </div>  
    
    </template>
    
    <script>
    import {
      BButton, BRow, VBPopover, BCol, BCard,BSkeleton
    } from 'bootstrap-vue'
    import ModalCreateNegocio from './ModalCreateNegocio.vue'
      
    
    
    export default {
      components: {
        ModalCreateNegocio,
        BSkeleton,
        BButton,
        BRow,
        BCol,
        BCard,

      },
      directives: {
        'b-popover': VBPopover,
      },
      props: ['userId','tokenAuth',],
      data() {
        const datos_json = JSON.parse(this.$userGlobal);

    
        return {
          openModalNegocio:false,
          loading:true,
          hasPlan:false,
          DateProxPay:"",
          id:"MKu7aBE8ARFnEXItWqWwBMdn1q0aKGSTBDZNqs4uLdKgB99idSkTPTOHZWn2IzCt",
          showCreateNegocio:false,
          isProfileVerificated:datos_json.isProfileVerificated,
          numberLocales:0,
          componentKeyModalNegocio:100,
    
        }
      },
      computed: {
    
      },
      watch: {
    
      },
      activated(){
        this.getPlan();
      },
      mounted() {

        this.getPlan();

        this.$eventBus.$on('createBusinessMethod', () => {
          
          this.createBusiness()
      })

        this.$eventBus.$on('reiniciarModalNegocio', () => {
      this.componentKeyModalNegocio += 1
      this.openModalNegocio = false;
    });

    
      },
      methods: {

       
        createBusiness(){
  

          if(this.isProfileVerificated){


                if(this.hasPlan){

                  this.openModalNegocio=true;




                }else{
                    this.$swal({
                                    title: 'Debe adquirir una subcripción para crear un nuevo negocio',
                                    text: `¿Deseas comprar una subcripción ahora?`,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Adquirir subcripción',
                                    cancelButtonText: 'No',
                                    customClass: {
                                    confirmButton: 'btn btn-primary',
                                    cancelButton: 'btn btn-outline-secondary ml-1',
                                    },
                                    buttonsStyling: false,
                                }).then(result => {
                                    if (result.value) {

                                      this.$router.push({ name: 'marketplaceparam',  params: { id: this.id } })

                                    
                                    }
                                })
                }
                

          }else{

            this.$swal({
                      title: 'Tu perfil debe estar verificado para realizar esta acción',
                      text: `¿Deseas verificar tu cuenta ahora?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Verificar cuenta',
                      cancelButtonText: 'No',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {

                        this.$router.push({ name: 'perfil', hash: '#verificacion' })

                      }
                    })

          }

                    

              





                },

        getPlan(){

          this.loading=true;

          this.$https.post('/subcripcion/getPlan/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {

       
                  if (response.data.code == 200) {

                      this.loading = false;

                      this.hasPlan=true;       
                      this.DateProxPay=response.data.DateProxPay;

                      this.isProfileVerificated= response.data.isProfileVerificated;

                      this.numberLocales= response.data.numberLocales;

                      if(Number(this.numberLocales) < Number("5")){

                        this.showCreateNegocio=true;

                        }



                  } else {




                  if (response.data.code == 401) {

                      this.$toast.error(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                      })
                      
                      localStorage.removeItem('userData')


                      this.$router.push({ name: 'auth-login' })
                  } else {

                      if(response.data.code == 404){
      
                        this.loading = false;

                        this.hasPlan=false;

                      }else{


                          this.getPlan();
                      

                      
                      }
                      
                  }
                  }
                  }).catch(error => {
                  this.getPlan();
                  })


        }
    
       
    
      },
    }
    </script>
    
    <style lang="scss">
    
    
    
    </style>
    